import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import "./blogdetail.css";
import data from '../../config/data.json';

export default function BlogDtail() {

    const { blogId } = useParams(); // Extract blogId from URL
    const navigate = useNavigate();

    // Find the blog by ID
    const post = data.blogs.find(blog => blog.id === blogId);
    console.log("post :", post)

    return (

        <div className='container-fluid' style={{ backgroundColor: 'white' }}>
            <div className="container blog-main-section">
                <h2 className='blog-heading'>{post.heading}</h2>
                <h6 className='blog-sub-heading'>{post.sub_heading}</h6>
                <img src={post.top_image} alt='random' className='blog-image' />

                <div className='blog-back blog-back-margin'>
                    <h5 className='blogger-name click grey' onClick={() => navigate(-1)}>Back</h5>
                </div>

                <div className='blogger-div'>
                    <img src={post.founder_image} className='blogger-image' alt='blogger' />
                    <text className='blogger-name'>{post.founder_name}</text>
                </div>

                <div className='blog-explanation'>
                    {post?.topics?.map((item, index) =>
                        <>
                            {(index % 3 === 2 && item.heading && index < post.topics.length - 1) && <div className='blogger-div click'>
                                <h5 className='blogger-name click grey' onClick={() => navigate(-1)}>Back</h5>
                            </div>
                            }
                            <div className='image-box'>
                                {item.image && <img src={item.image} alt={item.image_caption} className='topic-images' style={{ marginTop: 20, marginBottom: 20 }} />}
                                {item.iframe && <iframe src={item.iframe} title={item.sub_heading} id={item.iframe} alt={item.image_caption} className='topic-iframe' style={{ height: `${item.iframe.indexOf('youtube') > -1 ? '500px' : '70vh'}`, marginTop: 20, marginBottom: 20 }} />}
                                {item.image_caption && <div className='blogger-image-caption'>
                                    <h6 className='blogger-name'>{item.image_caption}</h6>
                                </div>
                                }
                            </div>

                            {(item?.link || item?.image) && <div className='blog-gap click'>
                                {item?.link && (item?.link.search("http") >= 0) ? <a href={item.link} className='link' target={"_blank"} rel={"noreferrer"}>{item.link_text ? item.link_text : item.link}</a>
                                    : <h5 className='blogger-name click link' onClick={() => navigate(item.link)}>{item.link_text ? item.link_text : item.link}</h5>}
                            </div>}

                            {item.heading && <h4 className='topic-heading'>{item.heading}</h4>}
                            {item.sub_heading && <h5 className='topic-subheading'>{item.sub_heading}</h5>}
                            {item.paragraphs && item.paragraphs.map(text =>
                                <p className='blog-description'>
                                    {text}
                                </p>
                            )}

                            {(index === post.topics.length - 1) && <div className='blog-back click'>
                                {(index === post.topics.length - 1) &&
                                    <h5 className='blogger-name click'>
                                        <span className="grey" onClick={() => navigate(-1)}>Back</span> <span className="grey" onClick={() => navigate("/")}>Home</span>
                                    </h5>
                                }
                            </div>}

                        </>
                    )}
                </div>

            </div>
        </div >
    )
}