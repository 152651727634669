import React from 'react'
import GroupCard from '../card/GroupCard';
import HeroSection from '../hero/HeroSection';


const SmartLearning = () => {

    const cardData = [
        {
            imageUrl: require("../../assets/images/learning/college-lab-min.png"),
            icon: "ri-brush-4-line",
            title: "Lab For College & University ",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../../assets/images/learning/school-lab-min.png"),
            icon: "ri-calendar-check-line",
            title: "Lab For 6th - 12th Grade",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../../assets/images/learning/workshop-min.png"),
            icon: "ri-movie-2-line",
            title: "Workshops and Training",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        }
    ];
    return (
        <div style={{ backgroundColor: 'rgba(0,6,18,0.9)' }}>
            <HeroSection
                bgImage={require("../../assets/images/learning/learning-bg-min.png")}
                title="Powering"
                subtitle="Smart Learning"
                headinglevel="Industry 4.0 & Smart Manufacturing labs"
                headingleveltwo="and Workshops"
                titleFont="Audiowide"
                subtitleFont="Roboto Slab"
                path={"/smart-learning-3"}
            />
            <div style={{marginTop:15}}>
            <GroupCard
                cards={cardData}
            />
            </div>
        </div>
    )
}

export default SmartLearning;