import React from 'react';
import "./groupcard.css"

const GroupCard = ({ cards }) => {
    return (
        <div id="about-boxes" className="about-boxes">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    {cards.map((card, index) => (
                        <div key={index} className="col-lg-4 col-md-6 d-flex align-items-center justify-content-center card-box" data-aos-delay={`${100 + index * 100}`}>
                            <div className="card" style={{ borderRadius: 15, margin: 10 }}>
                                <img src={card.imageUrl} className="card-img-top p-2" id='card-image'  alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title text-center "><p style={{ color: 'black' }}>{card.title}</p></h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default GroupCard;
