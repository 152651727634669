import React from 'react';
import "./about.css";

const LearningAbout = (props) => {
    const { imageurl, heading, description } = props;
    return (
        <section id="about" className="about bg-white">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-end image-container">
                        {/* Use the imageSrc prop for the image source */}
                        <img src={imageurl} className="img-fluid about-image" alt="" />
                    </div>
                    <div className="col-lg-7 pt-4 pt-lg-0 order-2 order-lg-1 content d-flex flex-row justify-content-center align-items-center">
                        {/* Use the heading prop for the heading text */}
                        <h2 className='learning-about-heading  text-center text-dark'>{heading}</h2>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LearningAbout