import React from 'react';
import "./about.css";

const About = (props) => {
    // You can destructure props for clearer access to individual properties.
    const { imageurl, heading, description, description2,description3 } = props;

    return (
        <div id="about" className="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-end image-container">
                        {/* Use the imageSrc prop for the image source */}
                        <img src={imageurl} className="img-fluid about-image" alt="" />
                    </div>
                    <div className="col-lg-7 pt-4 pt-lg-0 order-2 order-lg-1 content">
                        {/* Use the heading prop for the heading text */}
                        <h2 className='who-we-are-heading'>{heading}</h2>
                        {/* Use the description prop for the paragraph text */}
                        <p className='about-description-paragraph'>
                            {description}
                        </p>
                        <p className='about-description-paragraph'>
                           {description2}
                        </p>
                        <p className='about-description-paragraph'>
                           {description3}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
