import React from 'react'
import GroupCard from '../card/GroupCard';
import HeroSection from '../hero/HeroSection';


const SmartManufacturing = () => {

    const cardData = [
        {
            imageUrl: require("../../assets/images/manufacturing/welding-robot-min.png"),
            icon: "ri-brush-4-line",
            title: "Welding Robot",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../../assets/images/manufacturing/manufacturing-img-3.png"),
            icon: "ri-calendar-check-line",
            title: "Point & Click Software",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../../assets/images/manufacturing/ai-consultant-min.png"),
            icon: "ri-movie-2-line",
            title: "AI Consultant",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        }
    ];
    return (
        <div>
            <HeroSection
                bgImage={require("../../assets/images/manufacturing/manufacturing-bg-min.png")}
                title="Powering"
                subtitle="Smart Manufacturing"
                headinglevel="AI Vision Platform"
                headingleveltwo="For Robots & Manufacturing"
                titleFont="Audiowide"
                subtitleFont="Roboto Slab"
                path ="/smart-manufacturing"
            />
            <div style={{marginTop:15}}>
                <GroupCard
                    cards={cardData}
                />
            </div>
        </div>
    )
}

export default SmartManufacturing;