import React from 'react';
import './whychoose.css'; // Assuming your CSS is in the same folder

function WhyChooseSection(props) {
    // Extract reasons from props
    const { title, reasons } = props;

    return (
        <div className="main-section">
            <div className="content-container container bg-light">
                <h1 className='why-choose-heading'>{title}</h1>
                <ul className='reasons-ul'>
                    {reasons.map((reason, index) => (
                        <li key={index}>{reason}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default WhyChooseSection;
