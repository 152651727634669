import React from 'react'
import './header.css'
import { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Header = () => {
    const [colorChange, setColorchange] = useState(false)
    const [isToggled, setIsToggled] = useState(false)

    const changeNavbarColor = () => {
        if (window.scrollY >= 580) {
            setColorchange(true)
        } else {
            setColorchange(false)
        }
    }

    const toggleButton = () => {
        setIsToggled(!isToggled)
    }

    const scrollToSection = sectionId => {
        console.log('section Id :', sectionId)
        const section = document.getElementById(sectionId)
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' })
        }
    }
    window.addEventListener('scroll', changeNavbarColor)
    return (
        <nav
            className={
                colorChange
                    ? 'navbar navbar-expand-lg navbar-light fixed-top color-change'
                    : 'navbar navbar-expand-lg navbar-light fixed-top'
            }
        >
            <div className={isToggled ? 'nav-bg container' : 'container'}>
                <a className='navbar-brand text-white mx-0' href='#'>
                    <img
                        src={require('../../assets/icons/terafac-logo.png')}
                        alt=''
                        width='70'
                        height='70'
                        className='d-inline-block align-text-center rounded-circle'
                    />
                </a>
                <button
                    className='navbar-toggler bg-white '
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={toggleButton}
                >
                    <FaBars />
                </button>
                <div className='collapse navbar-collapse ' id='navbarSupportedContent'>
                    <ul className='navbar-nav nav-alignment d-flex justify-content-center align-items-md-start align-items-lg-center align-items-xl-center align-items-xxl-center ms-auto mb-2 mb-lg-0'>

                        <li className='px-4 nav-item'>
                            <Link
                                className='nav-link text-white navigation-buttons fw-light mx-0 px-0'
                                to={'/'}
                            >
                                Home
                            </Link>
                        </li>

                        <li className=' nav-item px-4'>
                            <Link
                                className='nav-link text-white navigation-buttons mx-0 px-0 fw-light'
                                to={"/smart-manufacturing"}
                            >
                                Smart Manufacturing
                            </Link>
                        </li>

                        <div className="dropdown nav-item px-4">
                            <a className="nav-link dropdown-toggle text-white navigation-buttons mx-0 px-0 fw-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Smart Learning
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <Link
                                        className="dropdown-item nav-link text-dark navigation-buttons  px-3 fw-light"
                                        to={"/lab-for-school"}>Lab for School
                                    </Link>
                                </li>
                                <li><Link
                                    className="dropdown-item nav-link text-dark navigation-buttons  px-3 fw-light"
                                    to={"/lab-for-college"}>Lab for College/ University</Link>
                                </li>
                                <li>
                                    <Link
                                        className="dropdown-item nav-link text-dark navigation-buttons  px-3 fw-light"
                                        to={"/trainingworkshop"}>Training & Workshops</Link>
                                </li>
                            </ul>
                        </div>
                        {/* <li className='mx-3 nav-item'>
                            <Link
                                className='nav-link text-white navigation-buttons mx-0 px-0 fw-light'
                                to={"/learning"}
                            >
                                Smart Learning
                            </Link>
                        </li> */}
                        {/* <li className='mx-3 nav-item'>
                            <a
                                className='nav-link text-white navigation-buttons mx-0 px-0 fw-light'
                                href='#blog'

                            >
                                Blogs
                            </a>
                        </li> */}
                        {/* <li className=' nav-item mx-3 '>
                            <a
                                className='nav-link text-white navigation-buttons mx-0 px-0 fw-light'
                                href='#about'
                            >
                                About Us
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;
