import React from 'react';
import './manufacturinghero.css';

const ManufacturingHero = ({ backgroundImage, text = [], className, height = { default: '100vh', mobile: '50vh' }, borderRadius, backgroundSize = "cover" }) => {

    const getHeight = (heightObj) => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            return heightObj.mobile || heightObj.default;
        } else {
            return heightObj.default;
        }
    };

    const containerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        height: getHeight(height), // Adjusted to use height from props
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        backgroundSize: backgroundSize,
        backgroundPosition: 'center',
        borderRadius: borderRadius,
    };

  
    const getFontSize = (fontSizeObj) => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            return fontSizeObj.mobile || fontSizeObj.default;
        } else {
            return fontSizeObj.default;
        }
    };

    return (
        <div className={`${className || 'manufacturing-hero-container'} hero-dynamic-height`} style={containerStyle}>
            <div className='manufacturing-hero-text-overlay m-0 p-0'>
                {text.map((textItem, index) => (
                    <p key={index} className='manufacturing-hero-heading' style={{
                        fontSize: getFontSize(textItem.fontSize),
                        fontFamily: textItem.fontFamily,
                        color: textItem.color || 'white',
                        fontWeight: textItem.fontWeight,
                        marginTop: textItem.marginTop
                    }}>
                        {textItem.content}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ManufacturingHero;
