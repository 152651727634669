import React from 'react'
import Header from '../components/header/Header';
import HeroSection from '../components/hero/HeroSection';
import OtherHero from '../components/otherpageshero/OtherHero';
import GroupCard from '../components/card/GroupCard';
import About from '../components/about/About';
import ManufacturingHero from '../components/manufacturing/ManufacturingHero';
import WhyChooseSection from '../components/whychoose/WhyChooseSection';
import TalkToUsSection from '../components/talktous/TalkToUsSection';
import Footer from '../components/footer/Footer';
import "./page.css";
import ManufacturingAbout from '../components/about/ManufacturingAbout';
import CustomModal from '../components/modal/Modal';

const ManufacturinPage = () => {

    const cardData = [
        {
            imageUrl: require("../assets/images/manufacturing/manufacturing-img-2.png"),
            icon: "ri-brush-4-line",
            title: "Import Design",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/manufacturing/manufacturing-img-1.png"),
            icon: "ri-calendar-check-line",
            title: "Mark",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/manufacturing/manufacturing-img-3.png"),
            icon: "ri-movie-2-line",
            title: "Start Weld",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        }
    ];

    const whyChooseData = {
        title: "Why Choose Terafac for Your Robotic Welding Needs",
        reasons: [
            "Enabling cost-efficient welding for individual pieces.",
            "Utilizing a modular and scalable approach.",
            "Leveraging AI for automatic parameter adjustment, visual recognition, and welding.",
            "Eliminating the need for manual programming.",
            "Boosting productivity capacity by up to fourfold."
        ]
    };

    return (
        <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
            <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
                <Header />
                <div style={{ height: "100px", backgroundColor: "rgba(0,6,18,0.9)" }}>

                </div>
                <ManufacturingHero
                    backgroundImage={require("../assets/images/manufacturing/manufacturing-bg-min.png")}
                    text={[
                        {
                            content: "AI Vision Platform For Robots & Manufacturing ",
                            fontSize: { default: "58px", mobile: "28px", tablet: "34px" },
                            fontFamily: "Audiowide",
                            fontWeight: '700'
                        },
                    ]}
                    className=""
                    height={{ default: '100vh', mobile: '65vh' }}
                />
                <section>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                        <ManufacturingHero
                            backgroundImage={require("../assets/images/manufacturing/welding-robot-min.png")}
                            text={[
                                {
                                    content: "AI-Vision Platform for Welding Robots !",
                                    fontFamily: "Roboto Slab",
                                    fontWeight: '600',
                                    fontSize: { default: "40px", mobile: "28px" }
                                },
                                {
                                    content: "Terafac AI and Vision platform enables robots to autonomously scan, position, and weld, thereby eliminating the traditional dependence on robot programmers.",
                                    fontFamily: "Poppins",
                                    fontWeight: '400',
                                    marginTop: 25,
                                    fontSize: { default: "20px", mobile: "16px" }
                                },
                            ]}
                            className="container  p-0"
                            height={{ default: '70vh', mobile: '50vh' }}
                            borderRadius="15px"
                        />

                    </div>
                </section>

                <h2 className='point-click-heading container'>
                    Every Operator interface enabled by our “
                    <span className="point-click-software">Point & Click Software</span>”
                </h2>
                <GroupCard
                    cards={cardData}
                />
                <section>
                    <ManufacturingAbout
                        imageurl={require("../assets/images/smartmanufacturing-hero.png")}
                        industries={[
                            {
                                heading: "Manufacturing SME’s – Metal Fabrication : ",
                                description: "Providing specialized solutions for small and medium-sized manufacturing enterprises, focusing on efficient metal fabrication processes."
                            },
                            {
                                heading: "Transformation : ",
                                description: "Guiding businesses through transformative phases with strategic support, whether it's process optimization, digitalization, or organizational restructuring."
                            },
                            {
                                heading: "Agriculture : ",
                                description: "Empowering agriculture with innovative farming equipment solutions, enhancing productivity and sustainability for farmers."
                            }
                        ]}
                    />
                </section>
                <section>
                    <WhyChooseSection
                        title={whyChooseData.title}
                        reasons={whyChooseData.reasons}
                    />
                </section>
                <section>
                    <TalkToUsSection
                        heading="Reserve your welding robot demo today!"
                        buttonText="Talk To Us"
                    />
                     <CustomModal
                modalTitle="Talk To Us"
                closeButtonName="Close"
                actionButtonName="Submit"
            />
                </section>
                <section>
                    <ManufacturingHero
                        backgroundImage={require("../assets/images/manufacturing/welding-robot-min.png")}
                        text={[
                            {
                                content: "Coming Soon",
                                fontSize: { default: "40px", mobile: "28px" },
                                fontFamily: "Roboto Slab",
                                fontWeight: '600'
                            },
                            {
                                content: "AI Manufacturing Assistant ",
                                fontSize: { default: "20px", mobile: "16px" },
                                fontFamily: "Poppins",
                                fontWeight: '400',
                                marginTop: 25
                            },
                            {
                                content: "AI Quality Control Robot ",
                                fontSize: { default: "20px", mobile: "16px" },
                                fontFamily: "Poppins",
                                fontWeight: '400',
                                marginTop: 25
                            },
                        ]}
                        className="container p-0 "
                        height={{ default: '50vh', mobile: '50vh' }}
                        borderRadius={'15px'}
                    />
                </section>
                <Footer />
            </div>
        </div >
    )
}
export default ManufacturinPage;