import React, { useState } from 'react';
import "./contact.css"
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

const ContactSection = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Call the postSummaryToDiscord function with the form data
            await postSummaryToDiscord(formData);
            // Optionally, you can reset the form after submission
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };


    async function postSummaryToDiscord(formData) {
        try {
            const pseudoname = "chatgpt";
            const url = "https://discord.com/api/webhooks/1233360222018474036/0tvpmrNS_829AoQh2CdKy5HL-WKkW-Uby2y1uHjilvHJl31kx7z3A4hboaUVPtjdgOVD";
            const content = `Contact Page:\n\nFirst Name: ${formData.name}\n\nEmail: ${formData.email}\n\nSubject : ${formData.subject}\nMessage: ${formData.message}`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: pseudoname,
                    content: content
                }),
            });
            const discordResponse = await response.text();
            console.log("Discord Response:", discordResponse);
        } catch (err) {
            console.error("Error posting to Discord:", err.message);
        }
    }

    return (
        <section id="contact" className="contact" data-aos="fade-up" data-aos-delay="100">
            <div className="container">

                <div className="row gy-4">
                    <div className="col-lg-6">
                        <div className="info-item  d-flex flex-column justify-content-center align-items-center contact-card">
                            <i className="bi bi-map"><FaLocationDot /></i>
                            <h3>Our Address</h3>
                            <p>Chandigarh, India</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 ">
                        <div className="info-item d-flex flex-column justify-content-center align-items-center  contact-card">
                            <i className="bi bi-envelope"><MdEmail /></i>
                            <h3>Email Us</h3>
                            <p>info@terafac.com</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="info-item  d-flex flex-column justify-content-center align-items-center  contact-card">
                            <i className="bi bi-telephone"><FaPhoneAlt /></i>
                            <h3>Call Us</h3>
                            <p>+91 7217227220</p>
                        </div>
                    </div>
                </div>
                <div className="row gy-4 mt-1">
                    <div className="col-lg-6 ps-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13715.482640096103!2d76.74267993955075!3d30.750134400000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fede0acc93f01%3A0x3b3bd12db7d0c3d5!2sPanjab%20University%20Incubation%20Centre-RUSA!5e0!3m2!1sen!2sin!4v1714364736751!5m2!1sen!2sin" frameBorder="0" style={{ border: 0, width: '100%', height: '384px' }} allowFullScreen></iframe>
                    </div>

                    <div className="col-lg-6 pe-0 bg-tranparent">
                        <form onSubmit={handleSubmit} role="form" className="php-email-form contact-card">
                            <div className="row gy-4">
                                <div className="col-lg-6 form-group">
                                    <input type="text" name="name" className="form-control contact-card" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                                </div>
                                <div className="col-lg-6 form-group">
                                    <input type="email" className="form-control contact-card" name="email" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control contact-card" name="subject" id="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control contact-card" name="message" rows="5" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className='contact-card'>Send Message</button></div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default ContactSection;
