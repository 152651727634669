import React from 'react';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ManufacturinPage from './pages/ManufacturinPage';
import LearningPage from './pages/LearningPage';
import BlogDetail from './components/blog/BlogDetail';
import LabForCollege from './pages/LabForCollege';
import TrainingPage from './pages/TrainingPage';
import ContactPage from './pages/ContactPage';
import BlogPage from './pages/BlogPage';
import AboutPage from './pages/AboutPage';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/smart-manufacturing' element={<ManufacturinPage />} />
        <Route exact path='/lab-for-school' element={<LearningPage />} />
        <Route exact path='/lab-for-college' element={<LabForCollege />} />
        <Route exact path='/smart-learning-3' element={<LabForCollege />} />
        <Route exact path='/trainingworkshop' element={<TrainingPage />} />
        <Route exact path='/contact' element={<ContactPage />} />
        <Route exact path='/about-us' element={<AboutPage />} />
        <Route exact path='/blog-post' element={<BlogPage />} />
        <Route path="/blog/:blogId" element={<BlogDetail />} />
        <Route path="/tag/:blogId" element={<BlogDetail />} />
        <Route exact path='*' element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
