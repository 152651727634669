import React from 'react';
import "./about.css";

const ManufacturingAbout = (props) => {
    const { imageurl, industries } = props; // Assuming 'industries' is an array of objects passed as a prop

    return (
        <div id="about" className="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-center image-container">
                        {/* Use the imageurl prop for the image source */}
                        <img src={imageurl} className="img-fluid about-image" alt="Industries we serve" />
                    </div>
                    <div className="col-lg-7 pt-4 pt-lg-0 order-2 order-lg-1 content">
                        {/* Dynamic rendering of industries */}
                        <h2 className='who-we-are-heading'>{"Industries We Serve"}</h2>
                        {industries.map((industry, index) => (
                            <div key={index} className="industry">
                                {/* <li className="industry-heading">{industry.heading}</li> */}
                                <li className="industry-description">
                                    <span style={{fontWeight:'bold'}}>{industry.heading}</span>{industry.description}</li>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManufacturingAbout;
