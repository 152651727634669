import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TopheroSection from '../components/topsection/TopSection';
import SmartManufacturing from '../components/manufacturing/SmartManufacturing';
import SmartLearning from '../components/learning/SmartLearning';
import About from '../components/about/About';
import Blog from '../components/blog/Blog';
import ClientCarousel from '../components/partner/Partner';
import TalkToUsSection from '../components/talktous/TalkToUsSection';
import ScreenSize from '../components/ScreenSize';
import CustomModal from '../components/modal/Modal';


const HomePage = () => {
    return (
        <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
            <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>

                <Header />
                <TopheroSection />
                <section>
                    <SmartManufacturing />
                </section>
                <section>
                    <SmartLearning />
                </section>
                {/* <section>
                    <About
                        imageurl={require("../assets/images/about-pic.png")}
                        heading="Who Are We?"
                        description="Founded with a vision to empower smart manufacturing and smart learning, Terafac Technologies was established in 2021. The company is a DPIIT recognized startup with the aim to prepare the youth for Industry 4.0. The world is going through what we call the Fourth Industrial Revolution. The world is set to see a transformation that will be unmatched in scale, scope and complexity, with stakeholders from all walks of life from public and private sectors to academia and civil society."
                    />
                </section> */}
                <section>
                    <Blog />
                </section>
                <section>
                    <TalkToUsSection
                        heading="Upgrade your operations and education with smart solutions"
                        buttonText="Lets Talk !"
                    />
                     <CustomModal
                modalTitle="Lets Talk !"
                closeButtonName="Close"
                actionButtonName="Submit"
            />
                </section>
                <section>
                    <ClientCarousel />
                </section>
                <Footer />
            </div>
        </div>
    )
}

export default HomePage;