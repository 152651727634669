import React from 'react';
import "./hero.css";
import { BiBorderRadius } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const HeroSection = ({
    bgImage, title, subtitle, headinglevel, headingleveltwo, path,
    titleFont = 'Audiowide', subtitleFont = 'Roboto Slab'
}) => {
    const heroStyle = {
        width: '100%',
        height: '100%',
        background: `url(${bgImage}) top center`,
        backgroundSize: 'cover',
        backgorundRepeat: 'norepeat',
        position: 'relative',
        borderRadius: '15px'

    };

    const navigate = useNavigate();

    const titleStyle = {
        fontFamily: titleFont
    };

    const subtitleStyle = {
        fontFamily: subtitleFont
    };
    const handleClick = () => {
        navigate(path);
    };


    return (
        <div id="hero" >
            <div className="hero-container container" style={heroStyle}>
                <h1 className="title" style={titleStyle}>
                    {title}
                </h1>
                <h1 className="subtitle" style={titleStyle}>
                    {subtitle}
                </h1>
                <h2 className="headinglevel" style={subtitleStyle}>
                    {headinglevel}
                    <br></br>
                    {headingleveltwo}
                </h2>
                <button className="know-more-btn" onClick={handleClick}>Know more</button>
            </div>
        </div>
    )
}

export default HeroSection;
