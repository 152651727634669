import React from 'react';
import './talktous.css'; // Make sure the path is correct

const TalkToUsSection = (props) => {
    // Extract heading and buttonText from props
    const { heading, buttonText } = props;

    return (
        <div className="banner-container">
            <div className="container container-boxe">
                <div className='row'>
                    <div className='col-9 d-flex flex-row justify-content-start align-items-center heading-container'>
                        <p className='m-0 p-0 talk-to-us-heading'>{heading}</p>
                    </div>
                    <div className='col d-flex flex-row justify-content-center align-items-center '>
                        <button className='talk-to-us-button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">{buttonText}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TalkToUsSection;
