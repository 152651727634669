import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Blog from '../components/blog/Blog';



const BlogPage = () => {
    return (
        <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
            <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>

                <Header />
                <section>
                    <Blog />
                </section>
                <Footer />
            </div>
        </div>
    )
}

export default BlogPage;